import React, { lazy, Suspense } from "react";
import "./App.css";
import { useRoutes, BrowserRouter as Router } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import { Toaster } from "react-hot-toast";
import Loader from "./components/home/Loader";

const Home = lazy(() => import("./pages/home"));
const PrivacyPolicy = lazy(() => import("./pages/privacypolicy"));
const About = lazy(() => import("./pages/about"));
const EventDetails = lazy(
  () => import("./components/eventDetails/eventDetails")
);
const Contact = lazy(() => import("./components/contactUs/contact"));

const AppRoutes = () => {
  return useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    { path: "/home", element: <Home /> },
    { path: "/privacy-policy", element: <PrivacyPolicy /> },
    { path: "/about", element: <About /> },
    { path: "/event-details", element: <EventDetails /> },
    { path: "/contact-us", element: <Contact /> },
  ]);
};
function App() {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Toaster />

        <AppRoutes />
      </Suspense>
    </Router>
  );
}

export default App;
