import { AnimatePresence, motion } from "framer-motion";
import React from "react";

function Loader() {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ clipPath: "inset(100% 0 0 0)" }}
        animate={{ clipPath: "inset(0 0 0 0)" }}
        exit={{ clipPath: "inset(0 0 100% 0)" }}
        layoutId="homeScreen"
        key="loadingScreen"
        className="w-screen h-screen bg-background font-title top-0 z-[99] fixed flex justify-center items-center"
      >
        <div className="loader font-title"></div>
      </motion.div>
    </AnimatePresence>
  );
}

export default Loader;
